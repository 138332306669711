import { React, useEffect, useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';

import {
  Typography,
  Grid,
  InputAdornment,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

import {
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import { ControlledInputText } from '../../forms/InputText';
import { ControlledAutocomplete } from '../../forms/Autocomplete';
import { MODES } from '../../shared/ManageEntities';
import { TenderFormSubtitle } from '../../../shared/utils';
import { FormFields } from './FormFields';
import { CardBankingAccounts, ACHBankingAccounts, AmexTPSPBankingAccounts } from './banking/perType';

// Eventually replace with SystemConfig.PaymentMethods -
// but for now most of those are not supported, so lets keep using this hardcoded list
export const allPaymentMethodTypes = [
  {
    text: 'Credit/Debit Card',
    value: 'card',
  },
  {
    text: 'ACH',
    value: 'ach',
  },
  {
    text: 'Amex TPSP',
    value: 'amex_tpsp',
  },
];

export const TenderTypeForm = ({
  formProps = {},
  availableTypes = [],
  savedBankingInfo = {},
  mode,
}) => {
  const {
    control,
    setValue,
    formKey = '',
    clearErrors,
  } = formProps;
  const generateName = (formField) => {
    if (formKey.length) {
      return formKey.concat('.', formField);
    }
    return formField;
  };
  const paymentMethod = useWatch({
    control,
    name: generateName(FormFields.PAYMENT_METHOD_TYPE),
  });
  const integration = useWatch({
    control,
    name: generateName(FormFields.GATEWAY),

  });
  const serviceFeeType = useWatch({
    control,
    name: generateName(FormFields.SERVICE_FEE_TYPE),
  });
  const cardFundingType = useWatch({
    control,
    name: generateName(FormFields.CARD_FUNDING_TYPE),
  });
  const achFundingType = useWatch({
    control,
    name: generateName(FormFields.ACH_FUNDING_TYPE),
  });
  const amexTpspFundingType = useWatch({
    control,
    name: generateName(FormFields.AMEX_TPSP_FUNDING_TYPE),
  });
  const requireConvenienceFeesNetDepositAndCombinedDeposit = ((paymentMethod === 'ach' && achFundingType === 'fbo')
  || (paymentMethod === 'amex_tpsp' && amexTpspFundingType === 'fbo')
  || (paymentMethod === 'card' && cardFundingType === 'fbo'));

  useEffect(() => {
    clearErrors();
  }, [paymentMethod, integration]);

  const [visibility, setVisibility] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [acceptedCards, setAcceptedCards] = useState({
    visa: true,
    mastercard: true,
    discover: false,
    amexOptblue: false,
  });

  const onCardChange = (type) => setAcceptedCards({ ...acceptedCards, [type]: !acceptedCards[type] });

  return (
    <Grid container spacing={1} sx={{ mt: 0.5, paddingInline: 1 }}>
      <Grid item xs={12}>
        {mode === MODES.ADD && (
        <ControlledAutocomplete
          control={control}
          options={availableTypes}
          label="Payment method"
          name={generateName(FormFields.PAYMENT_METHOD_TYPE)}
          required
        />
        )}
      </Grid>
      {paymentMethod === 'card' && (
      <Grid item xs={12} sx={{ ml: 1 }}>
        <Typography variant="subtitle2">Select cards to be accepted:</Typography>
        <Controller
          control={control}
          name={generateName(FormFields.ACCEPTED_CARDS)}
          render={() => (
            <FormGroup
              onChange={() => setValue(FormFields.ACCEPTED_CARDS, acceptedCards)}
              style={{ display: 'flex', flexDirection: 'row' }}
              sx={{ ml: 1 }}
            >
              <FormControlLabel
                control={<Checkbox onChange={() => onCardChange('visa')} />}
                label="Visa"
                checked={acceptedCards.visa}
              />
              <FormControlLabel
                control={<Checkbox onChange={() => onCardChange('mastercard')} />}
                label="Mastercard"
                checked={acceptedCards.mastercard}
              />
              <FormControlLabel
                control={<Checkbox onChange={() => onCardChange('discover')} />}
                label="Discover"
                checked={acceptedCards.discover}
              />
              <FormControlLabel
                control={<Checkbox onChange={() => onCardChange('amexOptblue')} />}
                label="AMEX OptBlue"
                checked={acceptedCards.amexOptblue}
              />
            </FormGroup>
          )}
        />
      </Grid>
      )}
      <Grid item xs={12}>
        <ControlledAutocomplete
          control={control}
          options={[
            {
              text: 'Core Funding',
              value: 'core',
            },
            {
              text: 'External Funding',
              value: 'external',
            },
            {
              text: 'Dynamic Payout',
              value: 'dynamicPayout',
            },
          ]}
          label="Funding source"
          name={generateName(FormFields.FUNDING_SOURCE)}
          required
        />
      </Grid>
      {paymentMethod === 'card' && (
      <>
        <Grid item xs={12} sm={cardFundingType === 'fbo' ? 6 : 12}>
          <ControlledAutocomplete
            control={control}
            options={[
              {
                text: 'PayFac / FBO Funding',
                value: 'fbo',
              },
              {
                text: 'External Funding',
                value: 'PayFac / Dynamic Payout',
              },
              {
                text: 'PayFac / Processor',
                value: 'processor',
              },
            ]}
            label="Card funding type"
            name={generateName(FormFields.CARD_FUNDING_TYPE)}
            required
          />
        </Grid>
        {cardFundingType === 'fbo' && (
        <Grid item xs={12} sm={6}>
          <ControlledAutocomplete
            control={control}
            options={[
              {
                text: '0',
                value: '0',
              },
              {
                text: '1',
                value: '1',
              },
              {
                text: '2',
                value: '2',
              },
              {
                text: '3',
                value: '3',
              },
              {
                text: '4',
                value: '4',
              },
              {
                text: '5',
                value: '5',
              },
            ]}
            label="Card funding delay"
            name={generateName(FormFields.CARD_FUNDING_DELAY)}
            required
          />
        </Grid>
        )}
      </>
      )}
      {paymentMethod === 'ach' && (
      <>
        <Grid item xs={12} sm={achFundingType !== 'fbo' ? 6 : 12}>
          <ControlledAutocomplete
            control={control}
            options={[
              {
                text: 'ACH / TPS / FBO Funding',
                value: 'fbo',
              },
              {
                text: 'ACH / 3rd Party Funding',
                value: '3rdParty',
              },
            ]}
            label="ACH funding type"
            name={generateName(FormFields.ACH_FUNDING_TYPE)}
            required
          />
        </Grid>
        {achFundingType !== 'fbo' && (
        <Grid item xs={12} sm={6}>
          <ControlledAutocomplete
            control={control}
            options={[
              {
                text: '0',
                value: '0',
              },
              {
                text: '1',
                value: '1',
              },
              {
                text: '2',
                value: '2',
              },
              {
                text: '3',
                value: '3',
              },
              {
                text: '4',
                value: '4',
              },
              {
                text: '5',
                value: '5',
              },
            ]}
            label="ACH funding delay"
            name={generateName(FormFields.ACH_FUNDING_DELAY)}
            required
          />
        </Grid>
        )}
      </>
      )}
      {paymentMethod === 'amex_tpsp' && (
      <>
        <Grid item xs={12} sm={amexTpspFundingType === 'fbo' ? 6 : 12}>
          <ControlledAutocomplete
            control={control}
            options={[
              {
                text: 'Amex TPSP / FBO Funding',
                value: 'fbo',
              },
              {
                text: 'Amex TPSP / Processor',
                value: 'processor',
              },
            ]}
            label="Amex TPSP funding type"
            name={generateName(FormFields.AMEX_TPSP_FUNDING_TYPE)}
            required
          />
        </Grid>
        {amexTpspFundingType === 'fbo' && (
        <Grid item xs={12} sm={6}>
          <ControlledAutocomplete
            control={control}
            options={[
              {
                text: '0',
                value: '0',
              },
              {
                text: '1',
                value: '1',
              },
              {
                text: '2',
                value: '2',
              },
              {
                text: '3',
                value: '3',
              },
              {
                text: '4',
                value: '4',
              },
              {
                text: '5',
                value: '5',
              },
            ]}
            label="Amex TPSP funding delay"
            name={generateName(FormFields.AMEX_TPSP_FUNDING_DELAY)}
            required
          />
        </Grid>
        )}
      </>
      )}
      {requireConvenienceFeesNetDepositAndCombinedDeposit && (
        <Grid item xs={12} sm={6}>
          <ControlledAutocomplete
            control={control}
            options={[
              {
                text: 'Yes',
                value: 'true',
              },
              {
                text: 'No',
                value: 'false',
              },
            ]}
            label="Convenience fees net deposit"
            name={generateName(FormFields.CONV_FEES_NET_DEPOSIT)}
            required
          />
        </Grid>
      )}
      {requireConvenienceFeesNetDepositAndCombinedDeposit && (
      <Grid item xs={12} sm={6}>
        <ControlledAutocomplete
          control={control}
          options={[
            {
              text: 'Yes',
              value: 'true',
            },
            {
              text: 'No',
              value: 'false',
            },
          ]}
          label="CC/ACH combined deposit"
          name={generateName(FormFields.COMBINED_DEPOSIT)}
          required
        />
      </Grid>
      )}
      <Grid item xs={12} sm={6}>
        <ControlledAutocomplete
          control={control}
          options={[
            {
              text: 'Worldpay',
              value: 'worldpay',
            },
          ]}
          label="Processor"
          name={generateName(FormFields.PROCESSOR)}
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledAutocomplete
          control={control}
          options={[
            {
              text: 'Bridgecom',
              value: 'bridgecom',
            },
            {
              text: 'Bluefin',
              value: 'bluefin',
            },
            {
              text: 'Heritage Bank',
              value: 'heritageBank',
            },
          ]}
          label="Integration"
          name={generateName(FormFields.GATEWAY)}
          required
        />
      </Grid>

      <Grid item xs={12} sx={{ ml: 1, mt: 1 }}>
        <TenderFormSubtitle>Service Fees</TenderFormSubtitle>
      </Grid>
      <Grid item xs={12}>
        <ControlledAutocomplete
          control={control}
          options={[
            {
              text: 'Fixed',
              value: 'fixed',
            },
            {
              text: 'Percentage',
              value: 'percentage',
            },
            {
              text: 'Combination',
              value: 'combination',
            },
          ]}
          label="Fee Type"
          name={generateName(FormFields.SERVICE_FEE_TYPE)}
          required
        />
      </Grid>
      {
        ['fixed', 'combination'].includes(serviceFeeType) && (
          <Grid item xs={12} sm={serviceFeeType === 'combination' ? 6 : 12}>
            <ControlledInputText
              control={control}
              name={generateName(FormFields.SERVICE_FEE_FIXED_AMOUNT)}
              label="Fixed Amount"
              required
            />
          </Grid>
        )
      }
      {
        ['percentage', 'combination'].includes(serviceFeeType) && (
          <Grid item xs={12} sm={serviceFeeType === 'combination' ? 6 : 12}>
            <ControlledInputText
              control={control}
              name={generateName(FormFields.SERVICE_FEE_PERCENTAGE)}
              label="Percentage %"
              required
            />
          </Grid>
        )
      }

      {integration === 'bluefin' && (
      <>
        <Grid item xs={12} sx={{ ml: 1, mt: 1 }}>
          <TenderFormSubtitle>Gateways</TenderFormSubtitle>
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledInputText
            control={control}
            name={generateName(FormFields.GATEWAY_GROUP)}
            label="Gateway Group"
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ControlledInputText
            control={control}
            name={generateName(FormFields.GATEWAY_FEE_GROUP)}
            label="Gateway Fee Group"
            required
          />
        </Grid>
      </>
      )}

      <Grid item xs={12} sx={{ ml: 1, mt: 1 }}>
        <TenderFormSubtitle>Credentials</TenderFormSubtitle>
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledInputText
          control={control}
          name={generateName(FormFields.MERCHANT_ACCOUNT)}
          label="Merchant account"
          required
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ControlledInputText
          control={control}
          name={generateName(FormFields.MERCHANT_ACCOUNT_CODE)}
          label="Merchant account code"
          required
        />
      </Grid>
      {integration !== 'bluefin' && (
      <Grid item xs={12} sm={6}>
        <ControlledInputText
          control={control}
          name={generateName(FormFields.USER)}
          label="User"
          required
        />
      </Grid>
      )}
      <Grid item xs={12} sm={integration !== 'bluefin' ? 6 : 12}>
        <ControlledInputText
          control={control}
          name={generateName(FormFields.PASSWORD)}
          label="Password"
          type={visibility ? 'text' : 'Password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setVisibility(!visibility)}
                >
                  {visibility ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          required
        />
      </Grid>

      {paymentMethod === 'card' && (
        <CardBankingAccounts
          control={control}
          formKey={formKey}
          formProps={formProps}
          savedBankingInfo={savedBankingInfo}
        />
      )}
      {paymentMethod === 'ach' && (
        <ACHBankingAccounts
          control={control}
          formKey={formKey}
          formProps={formProps}
          savedBankingInfo={savedBankingInfo}
        />
      )}
      {paymentMethod === 'amex_tpsp' && (
        <AmexTPSPBankingAccounts
          control={control}
          formKey={formKey}
          formProps={formProps}
          savedBankingInfo={savedBankingInfo}
        />
      )}
    </Grid>
  );
};
